import React from 'react';
import MainNavbar from '../components/Navbars/MainNavbar.js';
import LandingPageHeader from '../components/Headers/LandingPage/LandingPageHeader.js';
import DefaultFooter from '../components/Footers/DefaultFooter.js';
import { Container } from 'reactstrap';

import { graphql, useStaticQuery } from 'gatsby';

export default function Page404() {
  const {
    landing: {
      content: {
        contacts: { phones, addresses, socialLinks },
      },
    },
  } = useStaticQuery(pageQuery);
  return (
    <>
      <MainNavbar socialLinks={socialLinks} />
      <div className='wrapper' id='top'>
        <LandingPageHeader socialLinks={socialLinks} title={'404 Not Found'}  />
        <Container fluid>
          <h2 className='title underline'>404 Not found</h2>
        </Container>
        <DefaultFooter phones={phones} addresses={addresses}  />
      </div>
    </>
  );
}
export const pageQuery = graphql`
  query {
    landing: mongodbCloserDevCmsPages(
      mongodb_id: { eq: "5f8f2295ea3e4769348cabc0" }
    ) {
      content {
        contacts {
          phones
          addresses {
            label
            link
          }
          socialLinks {
            facebook {
              icon
              url
            }
            linkedin {
              icon
              url
            }
          }
        }
      }
    }
  }
`;
